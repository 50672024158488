import React, { useContext, useState, useEffect, createElement } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { deleteFacebookToken, getFacebookToken, patchFacebookAccount, refreshFacebookToken } from '../integrations.resource';
import { Icon, PageHeaderMui } from 'components';
import { Box, Button, IconButton, Paper, Stack, TextField, Tooltip, Typography, Divider, Skeleton, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { BlockIcon, CancelIcon, CheckCircleIcon, CloseIcon, DefaultIntegrationIcon, PendingIcon, ShieldPersonIcon, SyncIcon, WarningRedIcon } from 'components/mui';
import { history } from '../../../root.component';
import { useLocation } from 'react-router-dom';
import { Dropdown } from '../../../components/dropdown/dropdown.component';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import { StaticTypeAheadMui } from 'components/static-type-ahead/static-type-ahead-mui.component';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};
const FACEBOOKICON = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAAoCAYAAABjPNNTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALTSURBVHgBzZkxTBRBFIZ/JtdIg4mWGjqwQuzEBBoKm7v2Kkho1JhoZWy4swMLE2MhatQGghUl0GgiBSQaO8RG6VATGyHQcCXsv5M5dvdm92Z2dpb7ks1ddmdv/33z5s177/pOApCDzV1g5w/wPTi2gu9HLeDw+Oz6yBXgYr/8rN0AJoaQmz4bkRTxagNY+BwXZAIF164DjRoweMnqVjORLuJ0TI/Zie0qcnUbuLdUjLgkzUBoo9p9XKbIxyvSej6hr76bybZqqsi7i8DyV5QCBX58lC5U6E6WKZDs7QO3n8tPHR2WnFsH5tdQCFHLJEOUDoarb086z8dELn8JrLgEJ8aH5Oqtjcqwk4Sxtf4m3WoPJ4Fn9fi5ivrCm+bX4USzKkNLFiNXs6+/DBZqdTQe/Ns+yVWc9nZFCTQl6W6hSIpzCTX0vaIEEm65PBShSPqiCw8mUThRa4Y++cEx3EwMp1+jRThLR5GVbeJWvI/jOEsVZjEuvkgYOnTs/JXxLy9r23KWxNYuvDG3CieUX4rNX/DGYQtOKAMK16n2CXcoHuL3AXoabqfCR55YJHv/I9uiCSqlMmXlvrREFK4B2/ygwiTAxpo29Ql/O5lkDPTDGjFwAaXCLMiGwcuByLRA7AsbkZwFzpywLS9dsQl5yoAVFu4LG+Y36h6S9qK6sdwqTWmLVJ0Gk8XDh16b7Tzfeqsff2fxbNfIA7N7Iihw6iZ6Ds6Oyq7CfJJT3mtEk+hQJOuJcYeGUtHQiizmFO0a5/2Mvro7D5KlSFsk1c8a9GV8MzUWtyKJdTBY857ntNNQTU1B19FmYVJQdoAndLW0fpCwGeyLbs/UNqxUSlbGvm7yLJF1M5tHDY+Lif5vYgyBLtCRfz4tdvo5vWycfjJ0K6PMnD9Eoex0sG+Zdz+mxaZvyTBjE5Ot/n1QMNFg4c5++o8gq/n3Qj+u/lqWtdzRwmMYuTgFmxL6hnUmfpQAAAAASUVORK5CYII=';
export const IntegrationsConfigureFacebook = props => {
  const {
    user,
    asCompany,
    hasPermission
  } = useContext(UserStateContext);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(null);
  const [tokenIsRefreshing, setTokenIsRefreshing] = useState(null);
  const [showDeleteCredentialModal, setShowDeleteCredentialModal] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [saving, setSaving] = useState(false);
  const loc = useLocation();
  const urlParams = new URLSearchParams(loc.search);
  const [renderIteration, setRenderIteration] = useState(0);
  const [companyOptions, setCompanyOptions] = useState({});
  useEffect(() => {
    if (user?.companies) {
      const options = user.companies.map(company => ({
        id: company.id,
        name: company.name
      }));
      options.unshift({
        id: 'none',
        name: 'None'
      });
      options.sort((a, b) => {
        if (a.name === 'None') return -1;
        if (b.name === 'None') return 1;
        return a.name.localeCompare(b.name);
      });
      setCompanyOptions(options);
    }
  }, [user?.companies]);
  useEffect(() => {
    if (!user || !asCompany?.id) {
      return;
    }
    if (!props.match.params.id) {
      history.push(`/integrations`);
    }
    setLoading(true);
    getFacebookToken(props.match.params.id).then(token => {
      setAccounts(token.ad_accounts);
      setToken(token);
      setLoading(false);
    });
  }, [hasPermission, asCompany?.id, renderIteration]);
  const refresh = () => {
    setTokenIsRefreshing(true);
    refreshFacebookToken(token.id).then(() => {
      setRenderIteration(i => i + 1);
      snackbarService.popup({
        type: 'success',
        message: 'Ad Accounts (audiences) synced'
      });
      setTokenIsRefreshing(null);
    }).catch(err => {
      if (err.toString().includes('500 (Internal Server Error)')) {
        setTokenIsRefreshing(null);
      }
      snackbarService.popup({
        type: 'error',
        message: 'Unable to refresh audiences. You may need to refresh permissions on your token'
      });
    });
  };
  const handleDeleteCredential = () => {
    deleteFacebookToken(token?.id).then(() => {
      setShowDeleteCredentialModal(null);
      history.push(`/integrations/manage/facebook`);
      snackbarService.popup({
        type: 'success',
        message: `Credential has been removed successfully!`
      });
    });
  };
  const handleSubmit = () => {
    setSaving(true);
    const accountsWithChanges = accounts.filter(account => account.company_changed);
    accountsWithChanges.forEach(account => {
      if (account.company.includes('none')) {
        account.company = [];
      }
    });
    Promise.all(accountsWithChanges.map(account => {
      return patchFacebookAccount(account.id, account.company);
    })).then(() => {
      setSaving(false);
      setHasChanges(false);
      snackbarService.popup({
        type: 'success',
        message: 'Companies have been connected successfully'
      });
      history.push(`/integrations/manage/facebook`);
    }).catch(() => {
      setSaving(false);
      snackbarService.popup({
        type: 'error',
        message: 'There was an error saving your changes'
      });
    });
  };
  return <>
      <div className="wrapper" style={{
      height: loading ? '100vh' : 'auto'
    }}>
        <PageHeaderMui type="Integrations and Apps" icon={<Icon name="custom-call_merge" size={34} />} />

        <Box sx={{
        p: '20px',
        display: 'flex',
        justifyContent: 'center'
      }}>
          <Paper sx={{
          borderRadius: '14px',
          maxWidth: '1080px',
          width: '800px',
          minHeight: loading ? '60vh' : 'none'
        }}>
            {loading ? <Box sx={{
            width: '100%',
            height: '100%'
          }}>
                <Box sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              justifyItems: 'center',
              alignItems: 'center'
            }}>
                  <CircularProgress />
                </Box>
              </Box> : <Stack sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}>
                <Stack sx={{
              py: '10px'
            }}>
                  <Stack>
                    <Box sx={{
                  px: '20px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  pb: '10px'
                }}>
                      <Stack direction="row" spacing="20px">
                        <Box>
                          <img src={FACEBOOKICON} height="40" width="40" alt="facebook" />
                        </Box>

                        <Box sx={{
                      display: 'flex',
                      alignItems: 'center'
                    }}>
                          <Typography sx={{
                        fontSize: '20px',
                        fontWeight: '300',
                        lineHeight: '28px'
                      }}>
                            {token?.name || 'Facebook'}
                          </Typography>
                        </Box>

                        {token && <Tooltip title="You have configured this credential">
                            <Stack direction="row" spacing="8px" sx={{
                        p: '7px',
                        borderRadius: '8px',
                        backgroundColor: '#C5EAD7'
                      }}>
                              <Box sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                                <CheckCircleIcon size="sm" fill="#3EB87B" />
                              </Box>
                              <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          pr: '4px'
                        }}>
                                <Typography sx={{
                            color: '#3EB87B'
                          }}>Connected</Typography>
                              </Box>
                            </Stack>
                          </Tooltip>}
                      </Stack>

                      <Box>
                        <IconButton onClick={e => {
                      history.push(`/integrations`);
                    }}>
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    </Box>
                    <Divider />
                  </Stack>

                  <Stack sx={{
                px: '20px',
                pt: '20px',
                pb: '10px'
              }}>
                    <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}>
                      <Typography sx={{
                    fontSize: '18px',
                    lineHeight: '26px'
                  }}> Ad Accounts</Typography>
                      {!!token.ad_accounts.length && hasPermission('integration.change_credential') && <Box>
                          <Button variant="contained" endIcon={tokenIsRefreshing && <CircularProgress sx={{
                      color: '#FFF'
                    }} size={'20px'} />} onClick={() => {
                      if (tokenIsRefreshing) {
                        return;
                      }
                      refresh();
                    }}>
                            {tokenIsRefreshing ? 'Resyncing' : 'Resync Audiences'}
                          </Button>
                        </Box>}
                    </Box>

                    <Box sx={{
                  py: '24px'
                }}>
                      <Typography>
                        Please connect each ad account audience to a Cinch company. If you recently created an ad account on facebook, and
                        it is not showing here, click the resync button
                      </Typography>
                    </Box>

                    <Box>
                      {!!token.ad_accounts.length ? <Box sx={{
                    border: '1px solid #E2E2E2',
                    borderRadius: '16px'
                  }}>
                          <Table>
                            <colgroup>
                              <col width="25%" />
                              <col width="25%" />
                              <col width="25%" />
                              <col width="25%" />
                            </colgroup>
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  <Typography variant="tableHeader">Ad Account </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="tableHeader"> Status </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="tableHeader"> Audiences </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="tableHeader"> Connected Companies </Typography>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {accounts.map((account, index) => <TableRow hover key={account.id}>
                                  <TableCell sx={{
                            borderBottom: accounts?.length == index + 1 ? 'none' : '1px solid rgba(224, 224, 224, 1)'
                          }}>
                                    {account.name}
                                  </TableCell>
                                  <TableCell sx={{
                            borderBottom: accounts?.length == index + 1 ? 'none' : '1px solid rgba(224, 224, 224, 1)'
                          }}>
                                    {account.company?.length > 0 ? <Box sx={{
                              width: '32px'
                            }}>
                                        <Box sx={{
                                p: '4px',
                                background: '#C5EAD7',
                                borderRadius: '8px'
                              }}>
                                          <CheckCircleIcon fill="#3EB87B" />
                                        </Box>
                                      </Box> : <Tooltip title="You need to select at least one option">
                                        <Box sx={{
                                width: '32px'
                              }}>
                                          <Box sx={{
                                  p: '4px',
                                  background: '#F3C2C2',
                                  borderRadius: '8px'
                                }}>
                                            <WarningRedIcon />
                                          </Box>
                                        </Box>{' '}
                                      </Tooltip>}
                                  </TableCell>
                                  <TableCell sx={{
                            borderBottom: accounts?.length == index + 1 ? 'none' : '1px solid rgba(224, 224, 224, 1)'
                          }}>
                                    {account.audiences}
                                    {account?.has_wrong_source && <Tooltip title="This Ad Account contains Ad Audiences with the wrong data source.  They will be excluded from the journey.">
                                        <IconButton>
                                          <BlockIcon fill="#FFB82B" />
                                        </IconButton>
                                      </Tooltip>}
                                  </TableCell>
                                  <TableCell sx={{
                            borderBottom: accounts?.length == index + 1 ? 'none' : '1px solid rgba(224, 224, 224, 1)'
                          }}>
                                    <StaticTypeAheadMui sx={{
                              m: 1,
                              width: 300
                            }} multiple items={companyOptions} displayProperty="name" itemProperty="id" value={accounts.find(a => a.id === account.id)?.company?.map(id => id === 'none' ? {
                              id: 'none',
                              name: 'None'
                            } : user.companies.find(company => company.id === id)) || []} onChange={newValue => {
                              const index = accounts.indexOf(account);
                              const a = accounts;
                              if (newValue.some(option => option.id === 'none')) {
                                a[index].company = ['none'];
                              } else {
                                a[index].company = newValue.map(option => option.id);
                              }
                              a[index].company_changed = true;
                              setAccounts([...a]);
                              setHasChanges(true);
                            }} disableFreeSolo={true} label="Select Company*" getOptionLabel={option => option.name} placeholder="Search" />
                                    {accounts.find(a => a.id === account.id).company?.length == 0 && <Typography sx={{
                              color: '#D32F2F',
                              pl: '8px'
                            }}>Select at least one option</Typography>}
                                  </TableCell>
                                </TableRow>)}
                            </TableBody>
                          </Table>
                        </Box> : <Box sx={{
                    border: '1px solid #E2E2E2',
                    borderRadius: '16px'
                  }}>
                          <Table>
                            <colgroup>
                              <col width="25%" />
                              <col width="25%" />
                              <col width="25%" />
                              <col width="25%" />
                            </colgroup>
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  <Typography variant="tableHeader">Ad Account </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="tableHeader"> Status </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="tableHeader"> Audiences </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="tableHeader"> Connected Companies </Typography>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody sx={{
                        height: '200px'
                      }}>
                              <TableRow>
                                <TableCell sx={{
                            borderBottom: 'none'
                          }} colSpan={4}>
                                  <Box sx={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'center',
                              alignContent: 'center',
                              alignItems: 'center'
                            }}>
                                    <Stack spacing="24px">
                                      <Typography>
                                        There are no Ad accounts associated with this user. Please resync your audiences
                                      </Typography>
                                      <Box sx={{
                                  display: 'flex',
                                  justifyContent: 'center'
                                }}>
                                        {hasPermission('integration.change_credential') ? <Button sx={{
                                    width: '170px',
                                    backgroundColor: '#F3F3F4',
                                    '&:hover': {
                                      backgroundColor: '#F3F3F4'
                                    }
                                  }} variant="contained" endIcon={tokenIsRefreshing && <CircularProgress sx={{
                                    color: '#3898D9'
                                  }} size={'20px'} />} onClick={() => {
                                    if (tokenIsRefreshing) {
                                      return;
                                    }
                                    refresh();
                                  }}>
                                            <Typography sx={{
                                      color: '#3898D9'
                                    }}>
                                              {tokenIsRefreshing ? 'Resyncing' : 'Resync Audiences'}
                                            </Typography>
                                          </Button> : <>You do not have permission to resync audiences</>}
                                      </Box>
                                    </Stack>
                                  </Box>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </Box>}
                    </Box>
                    {!hasPermission('integration.change_credential') && <>You do not have permission to edit this page</>}
                  </Stack>

                  <Stack sx={{
                pt: '16px'
              }}>
                    <Divider />
                    <Box sx={{
                  p: '16px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}>
                      <Stack direction="row" spacing="16px">
                        {hasPermission('integration.delete_credential') && <Button variant="contained" sx={{
                      backgroundColor: '#F3F3F4',
                      '&:hover': {
                        backgroundColor: '#F3F3F4'
                      }
                    }} startIcon={<CancelIcon fill="#3898D9" />} onClick={e => {
                      setShowDeleteCredentialModal(true);
                    }}>
                            <Box sx={{
                        py: '5px',
                        pr: '12px'
                      }}>
                              <Typography sx={{
                          color: '#3898D9'
                        }}>Remove </Typography>
                            </Box>
                          </Button>}
                      </Stack>
                      <Stack direction="row" spacing="16px">
                        <Button variant="contained" sx={{
                      backgroundColor: '#F3F3F4',
                      '&:hover': {
                        backgroundColor: '#F3F3F4'
                      }
                    }} onClick={e => {
                      history.push(`/integrations/manage/facebook`);
                    }}>
                          <Box sx={{
                        py: '5px',
                        px: '12px'
                      }}>
                            <Typography sx={{
                          color: '#3898D9'
                        }}>Cancel </Typography>
                          </Box>
                        </Button>
                        <Button disabled={!hasPermission('integration.change_credential') || saving || !hasChanges || accounts.some(a => a?.company?.length == 0)} sx={{
                      backgroundColor: '#3898D9',
                      '&:hover': {
                        backgroundColor: '#3898D9'
                      }
                    }} variant="contained" endIcon={saving && <CircularProgress sx={{
                      color: '#FFF'
                    }} size={'20px'} />} onClick={e => {
                      handleSubmit();
                    }}>
                          <Box sx={{
                        py: '6px',
                        px: '12px'
                      }}>
                            <Typography sx={{
                          color: '#fff'
                        }}>{saving ? 'Working...' : 'Submit'}</Typography>
                          </Box>
                        </Button>
                      </Stack>
                    </Box>
                  </Stack>
                </Stack>
              </Stack>}
          </Paper>
        </Box>
      </div>

      <Dialog scroll="paper" PaperProps={{
      style: {
        borderRadius: '28px'
      }
    }} open={!!showDeleteCredentialModal} onClose={(e, r) => {
      setShowDeleteCredentialModal(null);
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <Stack spacing={'20px'}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Stack direction="row" spacing={'10px'}>
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <Typography sx={{
                  fontWeight: '300',
                  lineHeight: '30px',
                  fontSize: '24px',
                  display: 'flex',
                  alignItems: 'center'
                }} variant="h4" component="span">
                    Confirm Remove Facebook Credential
                  </Typography>
                </Box>
              </Stack>

              <IconButton onClick={() => {
              setShowDeleteCredentialModal(null);
            }}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider />
          </Stack>
        </DialogTitle>

        <DialogContent sx={{
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          marginTop: '20px',
          marginBottom: '20px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }}>
          <Stack sx={{
          pt: 1
        }} spacing={'24px'}>
            Are you sure you want to remove this credential?
          </Stack>
        </DialogContent>

        <DialogActions sx={{
        pt: 1,
        pb: 2
      }}>
          <Stack sx={{
          pr: '24px'
        }} direction={'row'} spacing={'20px'}>
            <Button onClick={() => {
            setShowDeleteCredentialModal(null);
          }} variant="contained" sx={{
            backgroundColor: '#F3F3F4',
            '&:hover': {
              backgroundColor: '#F3F3F4'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#3898D9'
              }}>Cancel </Typography>
              </Box>
            </Button>
            <Button onClick={() => {
            handleDeleteCredential();
          }} variant="contained" sx={{
            backgroundColor: '#3898D9',
            '&:hover': {
              backgroundColor: '#3898D9'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#fff'
              }}> Remove</Typography>
              </Box>
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>;
};